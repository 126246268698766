<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        From the table of thermodynamic data shown
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >, calculate the following thermodynamic properties, at 298 K, for:
      </p>

      <p class="pl-14 mb-5">
        <stemble-latex
          content="$\ce{2Al(s) + 3Cl2(g) -> 2AlCl3(s)}\hspace{2.0cm}\Delta\text{H}^\circ=-1408.4\text{kJ/mol}$"
        />
      </p>

      <p class="mb-3">
        <stemble-latex content="$\text{a) }\Delta \text{S}_{\text{sys}}$" />
      </p>

      <calculation-input
        v-model="inputs.Ssys"
        class="mb-6"
        dense
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{b) }\Delta \text{S}_{\text{surr}}$" />
      </p>

      <calculation-input
        v-model="inputs.Ssurr"
        class="mb-6"
        dense
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        <stemble-latex content="$\text{c) }\Delta \text{S}_{\text{univ}}$" />
      </p>

      <calculation-input
        v-model="inputs.Suniv"
        dense
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question285',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ssys: null,
        Ssurr: null,
        Suniv: null,
      },
    };
  },
};
</script>
